import BabylonScene from '../Babylon'


const Output = ({ data }) => {
  return (
    <div>
      <BabylonScene data={data} />
    </div>
  )
}

export default Output
