export const skyEnvironments = {
  CLEAR_SKY: "Clear sky",
  BLUE_SKY: "Blue sky",
  OVERCAST: "Overcast",
  PARTLY_CLOUDY: "Partly cloudy",
  SCATTERED_CLOUDS: "Scattered clouds",
  BROKEN_CLOUDS: "Broken clouds",
  HAZE: "Haze",
  SMOG: "Smog",
  STARRY_SKY: "Starry sky",
  MOONLIT_SKY: "Moonlit sky",
  CLEAR_NIGHT_SKY: "Clear night sky",
  TWILIGHT: "Twilight",
  CIVIL_TWILIGHT: "Civil twilight",
  NAUTICAL_TWILIGHT: "Nautical twilight",
  ASTRONOMICAL_TWILIGHT: "Astronomical twilight",
  DAYBREAK: "Daybreak",
  GOLDEN_HOUR: "Golden hour",
  MORNING_GLOW: "Morning glow",
  SHOWERS: "Showers",
  DRIZZLE: "Drizzle",
  TORRENTIAL_RAIN: "Torrential rain",
  THUNDERSTORM: "Thunderstorm",
  LIGHTNING: "Lightning",
  CUMULONIMBUS_CLOUDS: "Cumulonimbus clouds",
  FOG: "Fog",
  MIST: "Mist",
  RAINBOW: "Rainbow",
  AURORA_BOREALIS: "Aurora borealis (Northern Lights)",
  AURORA_AUSTRALIS: "Aurora australis (Southern Lights)"
};

