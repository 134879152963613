const COLORS = {
  '@primary-color': '#171717',
  '@link-color': '#0085F2',
  '@btn-active': '#fcb500',
  '@container-background': '#000000',
  '@container-content-background': '#000000',
  '@success-color': '#7AC2A1',
  '@warning-color': '#fcb204',
  '@error-color': '#F0485E',
  '@font-size-base': '14px',
  '@heading-color': 'rgba(0, 0, 0, .85)',
  '@text-color': '#fff',
  '@btn-height-lg': '50px',
  '@table-row-hover-bg': '#D3EBE0'
}

export const PLANET_MARS_COLORS = {
  '@color1': '#f0e7e7',
  '@color2': '#451804',
  '@color3': '#c1440e',
  '@color4': '#e77d11',
  '@color5': '#fda600'
}

export default COLORS
