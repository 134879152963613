import styled from 'styled-components'
import Modal from '../../UiKit/Modal'
export const StyledModal = styled(Modal)`
  .ant-modal-content {
    // width: 80vw;
    // height: 80vh;
    // position: relative;
    // top:0;
    // left:0;
    // background-color: red;
  }
`
